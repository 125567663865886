import * as Sentry from "@sentry/react";
import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";

import { ApiError, handleAxiosError } from "./ErrorHandler";

export async function requestInterceptor(
  req: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const token = await getAuth().currentUser?.getIdToken();
  req.headers["Content-Type"] = "application/json";
  req.headers.Authorization = `Bearer ${token}`;

  return req;
}

//This interceptor is used for uploading files
export async function requestFormDataInterceptor(
  req: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const token = await getAuth().currentUser?.getIdToken();
  req.headers["Content-Type"] = "multipart/form-data";
  req.headers.Authorization = `Bearer ${token}`;

  return req;
}

export function errorInterceptor(e: AxiosError<ApiError>): Promise<ApiError> {
  Sentry.captureException(e);
  return handleAxiosError(e);
}
