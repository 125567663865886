import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enOld from "./static/locales/en/translation.json";
import nbOld from "./static/locales/no/translation.json";
import nb from "./static/locales/nb.json";
import en from "./static/locales/en.json";

export const resources = {
  en: {
    translation: {
      ...enOld,
      ...en
    }
  },
  no: {
    translation: {
      ...nbOld,
      ...nb
    }
  }
} as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "no",
  debug: true,
  resources: resources
});

export default i18n;
