import {
  ProfilePutRequest,
  RoleAssignmentUpsertRequestResourceTypeEnum,
  User,
  UserApi,
  UserWithRole
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const userApi = new UserApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetUserById = createQuery({
  queryKey: ["getUserById"],
  fetcher: (variables: { companyId: string; userId: string }) =>
    getUserById(variables.userId, variables.companyId)
});

export const useGetUsersByCompanyId = createQuery({
  queryKey: ["getUsersByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    status: "active" | "deleted" | undefined;
  }) => {
    const { data } = await userApi.getAllUsersInCompany(
      variables.companyId,
      undefined,
      variables.status
    );

    return data;
  }
});

export const userQueries = createQueryKeys("users", {
  getById: (companyId: string, userId: string) => ({
    queryKey: [companyId, userId],
    queryFn: () => getUserById(userId, companyId)
  }),
  getProfile: () => ({
    queryKey: ["profile"],
    queryFn: () => getUserProfile()
  }),
  getByResourceId: (
    resourceId: string,
    resourceType: "order" | "project" | "task" | "company"
  ) => ({
    queryKey: [resourceType, resourceId],
    queryFn: async () => {
      const response = await userApi.getAllUsersOnResource(
        resourceId,
        resourceType
      );
      return response.data;
    }
  }),
  getByCompanyId: (companyId: string, status: "active" | "deleted") => ({
    queryKey: ["getByCompanyId", companyId, status],
    queryFn: async () => {
      const response = await userApi.getAllUsersInCompany(
        companyId,
        undefined,
        status
      );
      return response.data;
    }
  })
});

export async function getUsersOnResource(
  resourceId: string,
  resourceType: RoleAssignmentUpsertRequestResourceTypeEnum
): Promise<UserWithRole[]> {
  const { data } = await userApi.getAllUsersOnResource(
    resourceId,
    resourceType
  );

  return data;
}

export async function getUserById(userId: string, companyId: string) {
  const response = await userApi.getUserInformation(userId, companyId);
  return response.data;
}

export async function getUsers(
  companyId: string,
  status: "active" | "deleted" | undefined
): Promise<User[]> {
  const { data } = await userApi.getAllUsersInCompany(
    companyId,
    undefined,
    status
  );

  return data;
}

export async function getUserProfile(): Promise<User> {
  const { data } = await userApi.getInformationAboutCaller();
  return data;
}

export async function updateProfile(request: ProfilePutRequest): Promise<void> {
  await userApi.updateUserProfile(request);
}

export async function deleteUserCompanyConnection(
  userId: string,
  companyId: string
): Promise<void> {
  await userApi.deleteUserCompanyConnection(userId, companyId);
}
