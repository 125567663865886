import React from "react";
import App from "./App";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import "./i18n";
import { AppRouter } from "./router/AppRouter";
//const { replayIntegration } = await import("@sentry/react");
const env = import.meta.env.MODE;
import "./index.css";

Sentry.init({
  dsn: "https://b72ee06b50394b0588342b5ff75bd931@o4504769297776640.ingest.sentry.io/4504774574604288",
  tracesSampleRate: 0.7,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: env,
  enabled: env === "staging" || env === "prod",
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(AppRouter)]
});

//Sentry.addIntegration(replayIntegration());

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
