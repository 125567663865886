/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChecklistFromTemplateRequest } from '../model';
// @ts-ignore
import { ChecklistItemPutRequest } from '../model';
// @ts-ignore
import { ChecklistPostRequest } from '../model';
// @ts-ignore
import { ChecklistPutRequest } from '../model';
// @ts-ignore
import { ChecklistV2 } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
/**
 * ChecklistV2Api - axios parameter creator
 * @export
 */
export const ChecklistV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create checklist
         * @param {ChecklistPostRequest} checklistPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist: async (checklistPostRequest: ChecklistPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistPostRequest' is not null or undefined
            assertParamExists('createChecklist', 'checklistPostRequest', checklistPostRequest)
            const localVarPath = `/v2/checklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create checklist from template
         * @param {ChecklistFromTemplateRequest} checklistFromTemplateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromTemplate: async (checklistFromTemplateRequest: ChecklistFromTemplateRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistFromTemplateRequest' is not null or undefined
            assertParamExists('createFromTemplate', 'checklistFromTemplateRequest', checklistFromTemplateRequest)
            const localVarPath = `/v2/template/checklist/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistFromTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChecklist: async (id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChecklist', 'id', id)
            const localVarPath = `/v2/checklist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public checklists by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicChecklistsByResourceId: async (resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllPublicChecklistsByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v2/open/checklist/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklists by by ID
         * @param {string} checklistId ID of checklist to fetch
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistById: async (checklistId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('getChecklistById', 'checklistId', checklistId)
            const localVarPath = `/v2/checklist/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklists by project ID
         * @param {string} projectId Project ID to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistByProjectId: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getChecklistByProjectId', 'projectId', projectId)
            const localVarPath = `/v2/checklist/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklists by resource ID
         * @param {string} resourceId Resource ID (task or order) to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistByResourceId: async (resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getChecklistByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v2/checklist/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update checklist
         * @param {ChecklistPutRequest} checklistPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklist: async (checklistPutRequest: ChecklistPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistPutRequest' is not null or undefined
            assertParamExists('updateChecklist', 'checklistPutRequest', checklistPutRequest)
            const localVarPath = `/v2/checklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update list of checklist items
         * @param {string} checklistId 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistItems: async (checklistId: string, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('updateChecklistItems', 'checklistId', checklistId)
            // verify required parameter 'checklistItemPutRequest' is not null or undefined
            assertParamExists('updateChecklistItems', 'checklistItemPutRequest', checklistItemPutRequest)
            const localVarPath = `/v2/checklist/{checklistId}/checklistItems`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistItemPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistV2Api - functional programming interface
 * @export
 */
export const ChecklistV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create checklist
         * @param {ChecklistPostRequest} checklistPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklist(checklistPostRequest: ChecklistPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklist(checklistPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create checklist from template
         * @param {ChecklistFromTemplateRequest} checklistFromTemplateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFromTemplate(checklistFromTemplateRequest: ChecklistFromTemplateRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFromTemplate(checklistFromTemplateRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChecklist(id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChecklist(id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all public checklists by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublicChecklistsByResourceId(resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublicChecklistsByResourceId(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklists by by ID
         * @param {string} checklistId ID of checklist to fetch
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistById(checklistId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistById(checklistId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklists by project ID
         * @param {string} projectId Project ID to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistByProjectId(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklists by resource ID
         * @param {string} resourceId Resource ID (task or order) to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistByResourceId(resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistByResourceId(resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update checklist
         * @param {ChecklistPutRequest} checklistPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklist(checklistPutRequest: ChecklistPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklist(checklistPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update list of checklist items
         * @param {string} checklistId 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistItems(checklistId: string, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistItems(checklistId, checklistItemPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistV2Api - factory interface
 * @export
 */
export const ChecklistV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create checklist
         * @param {ChecklistPostRequest} checklistPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist(checklistPostRequest: ChecklistPostRequest, authorization?: string, options?: any): AxiosPromise<ChecklistV2> {
            return localVarFp.createChecklist(checklistPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create checklist from template
         * @param {ChecklistFromTemplateRequest} checklistFromTemplateRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromTemplate(checklistFromTemplateRequest: ChecklistFromTemplateRequest, authorization?: string, options?: any): AxiosPromise<ChecklistV2> {
            return localVarFp.createFromTemplate(checklistFromTemplateRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChecklist(id: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteChecklist(id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public checklists by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicChecklistsByResourceId(resourceId: string, options?: any): AxiosPromise<Array<ChecklistV2>> {
            return localVarFp.getAllPublicChecklistsByResourceId(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklists by by ID
         * @param {string} checklistId ID of checklist to fetch
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistById(checklistId: string, authorization?: string, options?: any): AxiosPromise<ChecklistV2> {
            return localVarFp.getChecklistById(checklistId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklists by project ID
         * @param {string} projectId Project ID to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistByProjectId(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<ChecklistV2>> {
            return localVarFp.getChecklistByProjectId(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklists by resource ID
         * @param {string} resourceId Resource ID (task or order) to fetch checklists for
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistByResourceId(resourceId: string, authorization?: string, options?: any): AxiosPromise<Array<ChecklistV2>> {
            return localVarFp.getChecklistByResourceId(resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update checklist
         * @param {ChecklistPutRequest} checklistPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklist(checklistPutRequest: ChecklistPutRequest, authorization?: string, options?: any): AxiosPromise<ChecklistV2> {
            return localVarFp.updateChecklist(checklistPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update list of checklist items
         * @param {string} checklistId 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistItems(checklistId: string, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: any): AxiosPromise<ChecklistV2> {
            return localVarFp.updateChecklistItems(checklistId, checklistItemPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistV2Api - object-oriented interface
 * @export
 * @class ChecklistV2Api
 * @extends {BaseAPI}
 */
export class ChecklistV2Api extends BaseAPI {
    /**
     * 
     * @summary Create checklist
     * @param {ChecklistPostRequest} checklistPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public createChecklist(checklistPostRequest: ChecklistPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).createChecklist(checklistPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create checklist from template
     * @param {ChecklistFromTemplateRequest} checklistFromTemplateRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public createFromTemplate(checklistFromTemplateRequest: ChecklistFromTemplateRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).createFromTemplate(checklistFromTemplateRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public deleteChecklist(id: string, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).deleteChecklist(id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public checklists by resource ID
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public getAllPublicChecklistsByResourceId(resourceId: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).getAllPublicChecklistsByResourceId(resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklists by by ID
     * @param {string} checklistId ID of checklist to fetch
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public getChecklistById(checklistId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).getChecklistById(checklistId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklists by project ID
     * @param {string} projectId Project ID to fetch checklists for
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public getChecklistByProjectId(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).getChecklistByProjectId(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklists by resource ID
     * @param {string} resourceId Resource ID (task or order) to fetch checklists for
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public getChecklistByResourceId(resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).getChecklistByResourceId(resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update checklist
     * @param {ChecklistPutRequest} checklistPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public updateChecklist(checklistPutRequest: ChecklistPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).updateChecklist(checklistPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update list of checklist items
     * @param {string} checklistId 
     * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistV2Api
     */
    public updateChecklistItems(checklistId: string, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ChecklistV2ApiFp(this.configuration).updateChecklistItems(checklistId, checklistItemPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
